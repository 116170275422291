<template>
  <v-col cols="12" md="4">
   <p class="mt-6">حمل تطبيقنا</p>
     <!-- <v-chip
      class="mr-2"
      text
      color="rgba(0, 0, 0, 0)"
      href="https://www.facebook.com/atabat.news/"
      target="_blank"
    >
      <v-img
      :src="require('@/assets/apple.png')"
        width="175"
        :aspect-ratio="3"
        cover
      ></v-img>
    </v-chip>
    <v-chip
      class="mr-2"
      tonal
      text-color="white"
      color="rgba(0, 0, 0, 0)"
      href="https://www.facebook.com/lawcultureg/"
      target="_blank"
    >
      <v-img
      :src="require('@/assets/google.png')"
      width="120"
        :aspect-ratio="3"
        cover
      ></v-img> </v-chip -->
      <div class="d-flex align-center bg-grey-lighten-4">
    <div>
        <a  href="https://apps.apple.com/au/app/%D9%85%D8%B1%D9%83%D8%B2-%D8%A7%D9%84%D9%82%D9%85%D8%B1/id6450648433"  target="_blank">
      <v-img
        class="bg-white"
        width="170"
        height="50" 
        :src="require('@/assets/apple.png')"
      ></v-img></a>
    </div>
    <div class="ma-2">
        <a  href="https://play.google.com/store/apps/details?id=com.turathalanbiaa.qr_center_app"  target="_blank">
      <v-img
        class="bg-white"
        width="170"
        height="50"
        :src="require('@/assets/google.png')"
        coer
      ></v-img></a>
    </div>
  </div>
  </v-col>
</template>

<script>
export default {
  name: "Tags",
  created() {},
  computed: {},
  methods: {},
};
</script>
